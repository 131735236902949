body {
    background-color: #EBEBED;
}


form {
    display: block;
    margin-top: 0em;
}


.full-page-bg {
    display: none;
}

.firebaseui-idp-icon-wrapper {
    display: none!important;
}

.firebaseui-idp-button {
    width: 100%!important;
    max-width: 100%!important;
    background-color: #10124E!important;
    width: 300px!important;
    padding: 1em!important;
    display: flex!important;
    justify-content: center!important;
    font-weight: 300!important;
}

.firebaseui-card-content {
    padding: 0!important;
    display: flex!important;
    justify-content: center!important;
}

.wrapper {
    display: flex;
    justify-content: center;
    padding-top: 5em;
}

.custom-container { 
    background: white;
    position: relative;
    width: 100%;
    padding: 2em;
    border-radius: 2px;
    min-width: 300px;

}

.logo-container {
    display: flex;
    justify-content: center;
}

.firebaseui-container {
    background-color: transparent!important;
    margin: 0 !important;
    max-width: 100%!important;
}

@media screen and (min-width: 600px) {
    .full-page-bg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: url("assets/background.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .custom-container {
        width: fit-content;
    }
}