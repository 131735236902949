body {
  background-color: #ebebed;
}

form {
  margin-top: 0;
  display: block;
}

.full-page-bg {
  display: none;
}

.firebaseui-idp-icon-wrapper {
  display: none !important;
}

.firebaseui-idp-button {
  background-color: #10124e !important;
  justify-content: center !important;
  width: 300px !important;
  max-width: 100% !important;
  padding: 1em !important;
  font-weight: 300 !important;
  display: flex !important;
}

.firebaseui-card-content {
  justify-content: center !important;
  padding: 0 !important;
  display: flex !important;
}

.wrapper {
  justify-content: center;
  padding-top: 5em;
  display: flex;
}

.custom-container {
  background: #fff;
  border-radius: 2px;
  width: 100%;
  min-width: 300px;
  padding: 2em;
  position: relative;
}

.logo-container {
  justify-content: center;
  display: flex;
}

.firebaseui-container {
  background-color: #0000 !important;
  max-width: 100% !important;
  margin: 0 !important;
}

@media screen and (width >= 600px) {
  .full-page-bg {
    background-image: url("background.75eb60ea.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }

  .custom-container {
    width: -moz-fit-content;
    width: fit-content;
  }
}

/*# sourceMappingURL=index.f09cd7dc.css.map */
